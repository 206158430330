import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import SimplePage from "../components/simple-page";
import Headline from "../components/typography/headline";
import Paragraph from "../components/typography/paragraph";

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      about: file(relativePath: { eq: "about.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="About" />
      <SimplePage
        title="About"
        description="Who we are, and what this means."
        fluid={data.about.childImageSharp.fluid}
      >
        <Headline>We are just like you.</Headline>
        <Paragraph>
          We are students, artists, and enthusiasts who see a future where
          anyone can be an artist and create masterpieces they could only have
          imagined. We are driving 12-year old cars, eating 23 cent ramen, and
          investing everything we have into our dream.
          <br />
          <br />
          A dream that one day art will mean something more than an expensive
          painting in a museum. That we can learn from the past, harness the
          creative genius and imaginations of Van Gogh, Picasso, Hokusai, and
          many more to forever change the way humanity creates artwork in the
          future.
          <br />
          <br />
          If we can even help one of our customers create an artistic
          masterpiece from a memory, a favorite place, even a loved one, then we
          have succeeded.
        </Paragraph>
      </SimplePage>
    </Layout>
  );
};

export default AboutPage;
